<template>
  <div>
    <!--    标题-->
    <div>
      <div style="text-align: center;margin-top: 30px;font-weight: bolder;font-size: 30px">
        {{ year }}年{{ jd }}季度
      </div>
      <div style="text-align: center;font-weight: bolder;font-size: 30px">
        职工食堂满意度调查表
      </div>
      <div style="padding-right: 10px;padding-left: 10px">
        <p style="text-indent:2em;">
          为持续提升职工食堂服务质量，保障职工以更加饱满的精神状态投入工作，综合管理部特开展此次职工食堂满意度调查。
        </p>
        <p style="text-indent:2em;">
          职工食堂满意度调查为不记名调查，每季度末开展一次，真诚欢迎广大干部职工提出宝贵意见和建议。此调查表请于{{ endtime }}前填写完毕。感谢您的支持与配合。
        </p>
      </div>
    </div>
    <!--    问题-->
    <div style="margin-left: 50px">
      <div v-for="(questions, index) in question" style="margin-bottom: 20px">
        <div>
          {{ index+1 + '、' }}{{ questions }}
        </div>
        <div style="margin-left: 20px;margin-top: 15px" v-for="(answer1, index1) in answer[index]" v-if="index !== 8">
          <el-radio-group v-model="que_answer[index]" class="ml-4">
            <el-radio :label="index+'*'+answer1" size="large">{{ answer1 }}</el-radio>
          </el-radio-group>
        </div>
        <div v-if="index === 8" style="margin-left: 20px;width: 80%;margin-top: 20px">
          <el-input
              v-model="otheryj"
              autosize
              type="textarea"
              placeholder="请输入您的意见和建议"
          />
        </div>
      </div>
    </div>
<!--提交-->
    <div style="float: right;margin-right: 60px;margin-bottom: 50px">
      <el-button type="primary" @click="upanswer()">提交</el-button>
    </div>

  </div>
</template>

<script>
import {setNowMonth2, setNowTimes} from "@/utils/time";
import { ElMessage } from 'element-plus'
export default {
  name: "question",
  data() {
    return {
      year: '',
      jd: '',
      endtime: '',
      question: [],
      answer: [],
      que_answer: [],
      otheryj: ''
    }
  },
  created() {
    let nowMonth2 = setNowMonth2();
    let strings = nowMonth2.split("-");
    this.year = strings[0];
    if (strings[1] === '01' || strings[1] === '02' || strings[1] === '03') {
      this.jd = '一'
    }
    if (strings[1] === '04' || strings[1] === '05' || strings[1] === '06') {
      this.jd = '二'
    }
    if (strings[1] === '07' || strings[1] === '08' || strings[1] === '09') {
      this.jd = '三'
    }
    if (strings[1] === '10' || strings[1] === '11' || strings[1] === '12') {
      this.jd = '四'
    }
    this.endtime = this.$route.query.date;
    // 获取问题、答案
    this.axios.get('/headquartersQuestion/getqueanw', (response) => {
      this.question = response.obj;
      this.answer = response.obj2;
    },{
      jd: this.year + this.jd
    })

  },
  methods: {
    upanswer(){
      let answerpeo = this.que_answer[0];
      for (let i = 1; i < this.que_answer.length; i++){
        answerpeo = answerpeo + '-' + this.que_answer[i]
      }
      this.axios.post('/headpeoanswers/upanswers', (response) => {
        if (response.obj){
          this.$router.push('/ydsuccess');
        }
      },{
        answers: answerpeo,
        otheryj: this.otheryj,
        jd: this.year + this.jd,
        uptime: setNowTimes(new Date())
      })
    }
  }
}
</script>

<style scoped>

</style>